import React, { useState, Suspense } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import Link, { NavLink } from "./Link";
import Nav from "./Nav";
import NotFound from "./NotFound";

import { Callback } from "./auth/Callback";
import { Logout } from "./auth/Logout";
import { LogoutCallback } from "./auth/LogoutCallback";
import { PrivateRoute } from "./auth/PrivateRoute";
import { SilentRenew } from "./auth/SilentRenew";

import useFetchPageMdAndRender from "../markdown/helpers/useFetchPageMdAndRender";
import { SearchablePageList, PageList } from "./PageList";
import {
  getRelatedApiPages,
  getRelatedTaxonomyPages,
} from "../markdown/helpers/getPages";

import Loader from "./Loader";
import PageBreak from "./PageBreak";

const Admin = React.lazy(() => import("./Admin"));
const Contact = React.lazy(() => import("./Contact"));
const DocsSubmissionForm = React.lazy(() => import("./DocsSubmissionForm"));

const HomeMain = styled.main`
  display: block;

  article {
    margin: auto;
    max-width: 800px;
    width: 100%;
  }

  h1:first-child {
    margin-top: 1.3rem;
  }
`;

const MainSearchPage = ({
  filteringPages,
  loading,
  myFilters,
  pages,
  showInternal,
  title,
  minimumTagOccurances,
  children,
}) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <main id="main" className={loading ? "loading" : ""}>
      {!loading && (
        <SearchablePageList
          filteringPages={filteringPages}
          showInternal={showInternal}
          title={title}
          pages={pages}
          myFilters={myFilters}
          minimumTagOccurances={minimumTagOccurances}
          children={children}
        />
      )}
    </main>
  </>
);

MainSearchPage.propTypes = {
  filteringPages: PropTypes.bool,
  loading: PropTypes.bool,
  myFilters: PropTypes.node,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      callback: PropTypes.func.isRequired,
      category: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      featured: PropTypes.bool,
      internal: PropTypes.bool,
      maintainers: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          email: PropTypes.string,
          apis: PropTypes.arrayOf(PropTypes.string),
        }),
      ),
      name: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(PropTypes.string),
      url: PropTypes.string,
    }),
  ),
  showInternal: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

const Routes = ({
  filteredApis,
  hasScopes,
  isLightcastEmployee,
  pages,
  user,
  showInternal,
}) => {
  const [filteringPages, setFilteringPages] = useState();

  const [mdPages, datasetPage] = useFetchPageMdAndRender(pages);
  const datasetsAreLoaded = !!datasetPage;

  const getPagesByCategory = category =>
    (filteringPages ? filteredApis : pages).filter(
      page => page.category === category,
    );

  const getPagesByTag = tag =>
    pages.filter(page => page.tags && page.tags.includes(tag));

  const apiPages = getPagesByCategory("apis").map(api => ({
    ...api,
    actions: [
      {
        name: "📖 API Reference",
        route: api.route,
      },
    ].concat(getRelatedApiPages(pages, api.name)),
  }));

  const taxonomyPages = getPagesByCategory("taxonomies").map(taxonomy => ({
    ...taxonomy,
    noHeaderLinks: true,
    actions: getRelatedTaxonomyPages(pages, taxonomy.name),
  }));

  const datasharesPages = getPagesByCategory("data-shares").map(datashares => ({
    ...datashares,
    actions: [
      {
        name: "🗄️ Data Reference",
        route: datashares.route,
      },
    ],
  }));

  const myFilters = [];

  if (hasScopes) {
    myFilters.push(
      <label htmlFor="orgfilter" key="org">
        <input
          checked={filteringPages || ""}
          type="checkbox"
          name="orgfilter"
          id="orgfilter"
          onChange={() => setFilteringPages(!filteringPages)}
        />{" "}
        My Org's APIs
      </label>,
    );
  }

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route exact path="/">
          <Helmet>
            <title>Lightcast API</title>
          </Helmet>
          <HomeMain id="main">
            <article>
              <h1>Documentation</h1>
              Welcome to Lightcast's API documentation! Get familiar with the
              Lightcast API products and explore available features or dive into
              our comprehensive reference documentation.
              <div className="pop-grid">
                <div className="pop-grid-item">
                  <Link rel="prefetch" to="/data-sets">
                    📊 Datasets
                  </Link>{" "}
                  View all of the datasets that Lightcast makes available via
                  API.
                </div>
                <div className="pop-grid-item">
                  <Link rel="prefetch" to="/apis">
                    📚 APIs
                  </Link>{" "}
                  Comprehensive documentation for all endpoints and code
                  examples.
                </div>
                <div className="pop-grid-item">
                  <Link rel="prefetch" to="/data-shares">
                    ☁️ Data Shares
                  </Link>{" "}
                  Data Shares table references, including field descriptions.
                </div>
                <div className="pop-grid-item">
                  <Link rel="prefetch" to="/taxonomies">
                    🚀 Taxonomies
                  </Link>{" "}
                  Comprehensive documentation for all taxonomy changelogs.
                </div>
                <div className="pop-grid-item">
                  <Link rel="prefetch" to="/guides">
                    📋 Guides
                  </Link>{" "}
                  Get up and running with quick start guides and additional
                  Lightcast API content.
                </div>
                <div className="pop-grid-item">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://status.lightcast.io/"
                  >
                    🚦 Status Page
                  </a>{" "}
                  See the current status of Lightcast products and any upcoming
                  planned maintenance.
                </div>
                <div className="pop-grid-item">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://kb.lightcast.io/"
                  >
                    🧠 Knowledge Base
                  </a>{" "}
                  Dive deep into Lightcast data and methodology or learn about
                  other products.
                </div>
              </div>
              <h1>Featured</h1>
              <PageList
                pages={apiPages.filter(page => page.featured).reverse()}
              />
            </article>
          </HomeMain>
        </Route>
        <PrivateRoute
          path="/login"
          component={() => (
            <main>
              <article>Logging in</article>
            </main>
          )}
        />
        <Route exact path="/login">
          <Helmet>
            <title>Lightcast API - Login</title>
          </Helmet>
          <main id="main">
            <article>Login</article>
          </main>
        </Route>
        <Route exact path="/logout" component={() => <Logout />} />
        <Route
          exact
          path="/logout/callback"
          component={() => <LogoutCallback />}
        />
        <Route path="/signin-oidc" component={() => <Callback />} />
        <Route exact path="/renew" component={() => <SilentRenew />} />
        {isLightcastEmployee && (
          <Route exact path="/account">
            {<Admin apis={filteredApis} user={user} />}
          </Route>
        )}

        <Route exact path="/contact">
          <HomeMain id="main">
            <Contact />
          </HomeMain>
        </Route>
        <Route exact path="/docs-submission-form">
          {isLightcastEmployee && <DocsSubmissionForm />}
        </Route>
        <Route exact path="/data-sets">
          <MainSearchPage
            showInternal={showInternal}
            key="data-sets"
            title="Datasets"
            pages={datasetPage ? datasetPage.pages : []}
          />
        </Route>
        <Route exact path="/apis">
          <MainSearchPage
            showInternal={showInternal}
            myFilters={!!myFilters.length && myFilters}
            filteringPages={filteringPages}
            key="apis"
            title="API Reference"
            pages={apiPages}
          />
        </Route>
        <Route exact path="/data-shares">
          <MainSearchPage
            showInternal={showInternal}
            myFilters={!!myFilters.length && myFilters}
            filteringPages={filteringPages}
            key="datashares"
            title="Data Shares"
            pages={datasharesPages}
          >
            <p>
              While Lightcast sets the global standard in labor market data,
              that's often just one piece of the puzzle for our customers: you
              may want to integrate that data with internal company records or
              other external trends specific to your industry. Receiving a data
              share from Lightcast makes it easier to combine our insight with
              the data sources and in the platforms your teams use.
            </p>
            <div className="pop-grid">
              <div className="pop-grid-item">
                <a
                  href="https://lightcast.io/resources/blog/lightcast-joins-snowflake-marketplace"
                  rel="noreferrer"
                  target="_blank"
                >
                  📣 Snowflake Marketplace Announcement
                </a>
                Read our launch announcement to learn more about our Snowflake
                offering.
              </div>
              <div className="pop-grid-item">
                <a
                  href="https://app.snowflake.com/marketplace/providers/GZT0ZGJHWM4/Lightcast"
                  rel="noreferrer"
                  target="_blank"
                >
                  ❄️ Snowflake Marketplace
                </a>
                View Lightcast on the Snowflake Marketplace and sign up for free
                trial access.
              </div>
            </div>
            <PageBreak />
            <h1>Full Reference</h1>
            <p>
              Tables available in Snowflake are listed below along with links to
              view their schema definitions. Schema definitions describe each
              column in the table.
            </p>
          </MainSearchPage>
        </Route>
        <Route exact path="/taxonomies">
          <MainSearchPage
            showInternal={showInternal}
            filteringPages={filteringPages}
            key="taxonomies"
            title="Taxonomies"
            pages={taxonomyPages}
            minimumTagOccurances={1}
          >
            <p>
              At Lightcast, we collect millions of labor market data points
              every day. In order to make use of them, we need to recognize how
              they connect. Our taxonomies are how: by organizing skills,
              occupations, and jobs into an understandable system, we can enable
              greater efficiency and optimization throughout the world of work
              and unlock new possibilities in the labor market.
            </p>
            <div className="pop-grid">
              <div className="pop-grid-item">
                <a
                  href="https://lightcast.io/our-taxonomies"
                  rel="noreferrer"
                  target="_blank"
                >
                  📖 Learn More
                </a>
                Read about the Lightcast skills, occupations and titles
                taxonomies.
              </div>
              <div className="pop-grid-item">
                <a
                  href="https://lightcast.io/open-skills/access"
                  rel="noreferrer"
                  target="_blank"
                >
                  🔑 Open API Access
                </a>
                Sign up for free API access to Lightcast skills and titles.
              </div>
              <div className="pop-grid-item">
                <a
                  href="https://app.snowflake.com/marketplace/providers/GZT0ZGJHWM4/Lightcast"
                  rel="noreferrer"
                  target="_blank"
                >
                  ❄️ Snowflake Access
                </a>
                Get access to Lightcast open taxonomies on Snowflake.
              </div>
            </div>
            <PageBreak />
            <h1>Full Reference</h1>
          </MainSearchPage>
        </Route>
        <Route exact path="/guides">
          <MainSearchPage
            showInternal={showInternal}
            key="guides"
            title="Guides"
            pages={getPagesByTag("guide")}
          />
        </Route>
        <Route exact path="/expired">
          <main id="main">
            <nav />
            <article>
              <h2>Expired Token</h2>
              <p>
                Sorry, but your session is expired, please{" "}
                <NavLink rel="prefetch" to="/logout">
                  Log Out
                </NavLink>{" "}
                and log back in to refresh your token.
              </p>
            </article>
          </main>
        </Route>
        {mdPages?.map(page => (
          <Route path={page.route} key={page.route}>
            <main id="main">
              <Nav />
            </main>
          </Route>
        ))}
        {/* </Route> */}
        <Route path="*">
          <NotFound datasetsAreLoaded={datasetsAreLoaded} />
        </Route>
      </Switch>
    </Suspense>
  );
};
Routes.propsTypes = {
  filteredApis: PropTypes.arrayOf(
    PropTypes.shape({
      callback: PropTypes.func.isRequired,
      category: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      featured: PropTypes.bool,
      internal: PropTypes.bool,
      maintainers: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          email: PropTypes.string,
          apis: PropTypes.arrayOf(PropTypes.string),
        }),
      ),
      name: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(PropTypes.string),
      url: PropTypes.string,
    }),
  ),
  filteringPages: PropTypes.bool.isRequired,
  hasScopes: PropTypes.number.isRequired,
  isLightcastEmployee: PropTypes.bool,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      callback: PropTypes.func.isRequired,
      category: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      featured: PropTypes.bool,
      internal: PropTypes.bool,
      maintainers: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          email: PropTypes.string,
          apis: PropTypes.arrayOf(PropTypes.string),
        }),
      ),
      name: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(PropTypes.string),
      url: PropTypes.string,
    }),
  ),
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  toggleFilter: PropTypes.func.isRequired,
};

export default Routes;
