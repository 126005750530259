import Fuse from "fuse.js";
import autoComplete from "js-autocomplete";

import { addEmoji } from "../markdown/helpers/getPages";

const getSearchDisplayCategory = type =>
  ({
    apis: "API Reference",
    "data-shares": "Data Shares",
    "data-sets": "Data Reference",
    guides: "Guide",
    taxonomies: "Taxonomies",
    tools: "Utility",
    updates: "Updates",
  }[type] || "");

// Need better documentation
export const validPathFilter = page => !page.route?.startsWith("/taxonomies"); // a little broken. out of scope though

const initHeaderSearch = (pages, history) => {
  const fuse = new Fuse(
    pages.filter(validPathFilter).map(page => ({
      ...page,
      tags: (page.tags || []).join(" "),
    })),
    {
      shouldSort: true,
      threshold: 0.2,
      location: 0,
      distance: 200,
      maxPatternLength: 32,
      minMatchCharLength: 2,
      tokenize: true,
      keys: [
        { name: "name", weight: 0.5 },
        { name: "tags", weight: 0.1 },
        { name: "description", weight: 0.4 },
      ],
    },
  );

  const searchInputSelector = 'header input[type="search"]';
  new autoComplete({
    selector: searchInputSelector,
    minChars: 2,
    source: function (term, suggest) {
      suggest(fuse.search(term).slice(0, 5));
    },
    renderItem: function (item, search) {
      const highlight = new RegExp(search, "ig");
      return `<div
        class="autocomplete-suggestion header"
        data-name="${item.item.name}"
        data-route="${item.item.route}"
        data-val="${item.item.name}"
      >
        ${addEmoji(item.item.name).replace(highlight, "<b>$&</b>")}
        <span class="search-type">
          ${getSearchDisplayCategory(item.item.category)}
        </span>
        <div class="search-description">
          ${item.item.description.replace(highlight, "<b>$&</b>")}
        </div>
      </div>`;
    },
    onSelect: function (e, term, item) {
      history.push(item.dataset.route);
      const searchInput = document.querySelector(searchInputSelector);
      searchInput.value = "";
      setTimeout(() => searchInput.blur(), 25);
    },
  });
};

export default initHeaderSearch;
